@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.filters {
  > a {
    color: $grey-600;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 0 16px 2px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &.active {
      border-bottom: 2px solid red;
    }
  }
}
